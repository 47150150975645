import React, { Component } from "react";
import {
  Row,
  Breadcrumb,
  Button,
  Form,
  message,
  DatePicker,
  Modal,
  Input,
  Select,
  Col,
  Radio,
  Popover,
  Table,
  TreeSelect,
} from "antd";
import { AreaSelect } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let setPageData = {},
  sendBody = {},
  Timer;

class MarketManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      currentPage: window.routerCache.MarketManage.currentPage || 1,
    };
    sendBody = window.routerCache.MarketManage.sendBody || {};
    setPageData = {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState(
      { tData: data, totalNum: totalNum, currentPage: currentPage },
      this.polling
    );
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });
      let send = sendBody;
      // send.workType = window.routerCache.MarketManage.send_workType || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios(
        "get",
        "/checkBill/reconciliation/page",
        send
      );
      this.setState(
        {
          loading: false,
          tData: res.data.dataList,
          totalNum: res.data.totalNum,
        },
        this.polling
      );
      setPageData[this.state.currentPage + ""] = res.data.dataList;
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  handleTableChange = async (pagination) => {
    this.setState({ loading: true });
    if (Object.keys(setPageData).includes(pagination.current + "")) {
      this.setState({
        loading: false,
        tData: setPageData[pagination.current + ""],
        currentPage: parseInt(pagination.current),
      });
    } else {
      try {
        let send = sendBody;
        send.pageNum = pagination.current + "";
        send.pageSize = "10";
        let res = await Axios(
          "get",
          "/checkBill/reconciliation/page",
          send
        );
        this.setState(
          {
            loading: false,
            tData: res.data.dataList,
            totalNum: res.data.totalNum,
            currentPage: pagination.current,
          },
          this.polling
        );
        setPageData[pagination.current + ""] = res.data.dataList;
      } catch (err) {
        // message.error(err.message);
        this.setState({ loading: false });
      }
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    this.refreshTable();
    window.winhcFn.changeDept = () => {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.refreshTable();
        }
      );
    };
  }

  componentWillUnmount () {
    window.routerCache.MarketManage.currentPage = this.state.currentPage;
    window.routerCache.MarketManage.sendBody = sendBody;
    if (Timer) {
      clearTimeout(Timer);
    }
  }

  polling = () => {
    let gonext = false;
    this.state.tData.forEach((item) => {
      if (item.status == "0") {
        gonext = true;
        return;
      }
    });
    if (!gonext) {
      return;
    }
    if (Timer) {
      clearTimeout(Timer);
    }
    // setTimeout(async () => {
    //     try {
    //         let send = sendBody;
    //         send.pageNum = this.state.currentPage + "";
    //         send.pageSize = '10';
    //         let res = await Axios('get', '/BrokerInfos/trial/legalcloud', send)
    //         if (this.state.currentPage == sendBody.pageNum) {
    //             this.setState({
    //                 tData: res.data.dataList,
    //                 totalNum: res.data.totalNum,
    //             }, this.polling);
    //             setPageData[this.state.currentPage + ''] = res.data.dataList;
    //         }
    //     } catch (err) {
    //         // message.error(err.message);
    //         console.log(err)
    //     }
    // }, 1000)
  };

  render () {
    const { refreshTable } = this;
    let columns = [
      {
        title: "ID",
        dataIndex: "legalCloudSettleId",
        fixed: "left",
        // width: 50,
      },
      {
        title: "对账单号",
        dataIndex: "settleNo",
        // width: 200,
        fixed: "left",
      },
      {
        title: "对账主体",
        dataIndex: "brokerName",
        // width: 150,
      },
      {
        title: "订单数",
        dataIndex: "settleOrderCount",
        // width: 120,
      },
      {
        title: "结算金额（元）",
        dataIndex: "finalSettleAmt",
        // width: 120,
        render: (text) => text + "元",
      },
      {
        title: "对账周期",
        dataIndex: "settleStartTime",
        // width: 180,
        render: (text, record) =>
          text.slice(0, 10) + "~" + record.settleEndTime.slice(0, 10),
      },
      {
        title: "结算状态",
        dataIndex: "settleStatus",
        // width: 120,
        render: (text) =>
          text == "1" ? "已结算" : text == "0" ? "未结算" : "-",
      },
      {
        title: "流程状态",
        dataIndex: "invoiceStatus",
        // width: 120,
        render: (text) =>
          text == "1" ? "已完成" : text == "0" ? "进行中" : "-",
      },
      {
        title: "操作",
        dataIndex: "operate",
        // width: 50,
        fixed: "right",
        render: (text, record) => (
          <Link
            to={"/MarketManageDetail/" + record.legalCloudSettleId}
          >
            查看
          </Link>
        ),
      },
    ];
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>销售对账管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">销售对账管理</span>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            <Search refreshTableByData={this.refreshTableByData} />

            <div style={{ backgroundColor: "#fff" }}>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
              ></Row>

              <Table
                scroll={{ x: 'max-content' }}
                dataSource={this.state.tData}
                columns={columns}
                size="middle"
                pagination={{
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(MarketManage);

class SearchM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      sDate: "",
      eDate: "", qingkong: false
    };
  }

  // 查询
  query = async () => {
    setPageData = {};
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();

    _this.props.refreshTableByData([], 1, 1);
    if (sendBody.date1) {
      sendBody.startDate = Filter.timetrans(sendBody.date1[0], "day");
      sendBody.endDate = Filter.timetrans(sendBody.date1[1], "day");
      delete sendBody.date1;
    }
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    Object.keys(sendBody).map((key) => {
      if (!sendBody[key]) {
        delete sendBody[key];
      }
    });
    try {
      let res = await Axios(
        "get",
        "/checkBill/reconciliation/page",
        sendBody
      );

      _this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
      setPageData["1"] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    this.setState({

      qingkong: true
    }, () => {
      this.setState({
        qingkong: false
      })
      this.formRef.current.resetFields();
    })

    sendBody = {};
    setPageData = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.MarketManage.send_workType || '';
    this.props.refreshTableByData([], 1, 1);

    try {
      let res = await Axios(
        "get",
        "/checkBill/reconciliation/page",
        sendBody
      );

      this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
      setPageData["1"] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 选择日期范围
  dateChange = (value, b) => {
    console.log(value, b);
    if (value.length !== 0) {
      // this.setState({sDate: b[0].replace(/-/g, '')});
      // this.setState({eDate: b[1].replace(/-/g, '')});
      this.setState({ sDate: b[0] });
      this.setState({ eDate: b[1] });
    } else {
      this.setState({ sDate: "" });
      this.setState({ eDate: "" });
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () { }

  render () {
    /*控制查询按钮状态*/
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row type="flex" align="middle">
            <Col span="8">
              <Form.Item
                label="对账单号"
                style={{ width: "100%" }}
                name="settleNo"
                initialValue={sendBody.settleNo}>
                <Input
                  placeholder="请输入"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="对账周期"
                style={{ width: "100%" }}
                name="date1"
                initialValue={sendBody.date1}>
                <RangePicker
                  style={{ width: "80%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="流程状态"
                style={{ width: "100%" }}
                name="invoiceStatus"
                initialValue={sendBody.invoiceStatus}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "80%" }}
                >
                  <Option key="0">进行中</Option>
                  <Option key="1">已完成</Option>
                  <Option key="5">已受理</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            type="flex"
            align="middle"
          >
            <Col span="8">

            </Col>
            <Col span="8">

            </Col>
            <Col span={8}>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{ width: "85%", height: '30px' }}
              >
                <Form.Item style={{ marginRight: '16px' }}
                >
                  <Button
                    type="primary"
                    onClick={this.query}
                  >
                    查询
                  </Button>
                </Form.Item>
                <Form.Item >
                  <Button onClick={this.clearOptions}>
                    重置
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <div>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={
                window.routerCache.MarketManage.workTypeStr
                  ? {
                    paddingBottom: "10px",
                  }
                  : { paddingBottom: "40px" }
              }
            ></Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(SearchM);
