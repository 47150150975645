import React from "react";
import { Button, Table, Row, Col, message, Form, Breadcrumb, Checkbox, Modal, Popover, Cascader, Select, DatePicker, Input, Tabs } from "antd";
import { Axios } from "../../axios";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormOutlined } from "@ant-design/icons";
import { Shengpi2, Tables } from "./Common";
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { AreaSelectForm, FlieList, popoverTable, ImgViewer, DocumentShow } from "../../common";
import filter from "../../common/filter";
import time from "../../common/filter";
import Filter from "../../common/filter";
import { Ossupload } from "../../common/upLoad";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
const { confirm } = Modal;
let detailCustId;
const TabPane = Tabs.TabPane;

class MainM extends React.Component {
  TablesRef = React.createRef();
  ConfirmRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingList: false,
      companyInfo: {},
      packageVO: {},
      hasServe: false,
      itemVOList: [],
      bizOperHis: [],
      legalServiceItemName: "",
      legalCompanyBasicInfo: [], //沟通记录，只会有一条
      feedBackTotalNum: 0,
      ManageallserveModalLoading: false,
      brokeFileList: [],
      DateList: [],
      legalSummaryInfos: [],

      serviceList: [],
      serviceTotalNum: 0,
      serviceLoading: false,
      serviceList1: {},
      serviceTotalNum1: 0,
      serviceLoading1: false,
      MainBodyList: [],
      Investigate: [],
      InvestigateLoading: false,
      InvestigateNum: 0,
      dataInfo: {},
    };
  }

  componentDidMount () {
    detailCustId = this.props.match.params.id;
    this.getData();
    this.getDataList(1);
  }

  getData = async () => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios("get", "/renew/list/" + detailCustId, {});
      if (res.code === "200") {
        this.setState(
          {
            dataInfo: res.data || {},
            companyInfo: res.data.customInfo || {},
            legalSummaryInfos: res.data.legalSummaryInfos || [],
            packageVO: res.data.lastOrder || {},
            legalCompanyBasicInfo: res.data.legalCompanyBasicInfo ? [res.data.legalCompanyBasicInfo] : [],
          },
          () => {
            this.getServiceList(1);
            this.getServiceList1(1);
            this.getInvestigateList(1);
            this.GetMainBody(res.data.customInfo || {});
          }
        );
        this.TablesRef.current.getOperhisList();
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  GetMainBody = async value => {
    try {
      let res = await Axios("get", "/legalcloud/mainBody/" + value.legalCloudId, {
        pageNum: 1,
        pageSize: 100,
        id: value.legalCloudId,
      });
      if (res.code == "200" || res.isSuccess == "isSuccess") {
        this.setState({ MainBodyList: (res.data || {}).dataList || [] });
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  getInvestigateList = async pageNum => {
    try {
      this.setState({
        InvestigateLoading: true,
      });
      let res = await Axios("post", "/renew/open/survey/list", {
        pageSize: 10,
        pageNum: pageNum,
        legalCloudId: this.state.companyInfo.legalCloudId,
        status: 1,
      });
      if (res.errorCode == "SUCCESS" || res.isSuccess == "T") {
        this.setState({
          Investigate: res.body,
          InvestigateNum: res.totalNum,
        });
      } else {
        message.error(res.errorMsg);
      }
      this.setState({ InvestigateLoading: false });
    } catch (err) {
      // message.error(err);
      this.setState({ InvestigateLoading: false });
    }
  };
  getServiceList = async pageNum => {
    try {
      this.setState({
        serviceLoading: true,
      });
      let res = await Axios("get", "/renew/open/my", {
        pageSize: 10,
        pageNum: pageNum,
        legalCloudId: this.state.companyInfo.legalCloudId,
      });
      if (res.isSuccess === "T" || res.errorCode == "SUCCESS") {
        this.setState({
          serviceList: res.body,
          serviceTotalNum: res.totalNum,
        });
      } else {
        message.error(res.message);
      }
      this.setState({
        serviceLoading: false,
      });
    } catch (err) {
      this.setState({ serviceLoading: false });
    }
  };
  getServiceList1 = async pageNum => {
    try {
      this.setState({
        serviceLoading1: true,
      });
      let res = await Axios("post", "/renew/open/company/" + this.state.companyInfo.legalCloudId + "/serviceList", {
        pageSize: 10,
        pageNum: pageNum,
      });
      if (res.errorCode === "SUCCESS" || res.isSuccess === "T") {
        this.setState({
          serviceList1: res.body || {},
          serviceTotalNum1: res.totalNum,
        });
      } else {
        message.error(res.message);
      }
      this.setState({
        serviceLoading1: false,
      });
    } catch (err) {
      this.setState({ serviceLoading1: false });
    }
  };
  DownloadReport = async id => {
    try {
      let res = await Axios("get", "/legal/survey/" + id + "/report", { id });
      if (res.isSuccess == "T" || res.errorCode == "SUCCESS") {
        this.downloadFile(res.body);
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  downloadFile = url => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = Filter.urlGetFileName(url);
        // link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
        link.click();
      });
  };
  getDataList = async currentPage => {
    const page = currentPage || this.state.currentPage;
    this.setState({
      loading: true,
    });
    try {
      let res = await Axios(
        "get",
        "/renew/followList/" + detailCustId,

        {
          pageNum: currentPage + "",
          pageSize: "1000",
        }
      );
      if (res.code == "00" || res.isSuccess == "T") {
        this.setState({
          DateList: res.body,
        });
      } else {
        message.error(res.message);
      }
    } catch (err) { }
  };
  render () {
    const cStyle = {
      padding: "12px 24px 12px 24px",
      marginBottom: "15px",
      borderRadius: "4px",
      background: "#fff",
      fontSize: "14px",
    };
    const tStyle = {
      color: "#222",
      lineHeight: "30px",
      fontWeight: "650",
      fontSize: "16px",
      margin: "0px -24px 15px -24px",
      borderBottom: "1px solid #efe5e5",
      padding: "0 24px 8px 24px",
    };
    const dStyle = {
      color: "#222",
      lineHeight: "30px",
      margin: "10px 0px 0px 0px",
    };
    const { companyInfo, packageVO, legalSummaryInfos, legalCompanyBasicInfo, serviceList1, serviceTotalNum1, serviceList, serviceTotalNum, serviceLoading1, serviceLoading, InvestigateNum, MainBodyList, Investigate, InvestigateLoading, dataInfo } = this.state;
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>客户管理</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/RenewCompanyManage">续费客户管理</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>续费客户详情</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">续费客户详情</span>
            <Shengpi2 refreshTable={this.getData} index={"2"} brokerUserId={companyInfo.brokerUserId} detailCustId={companyInfo.legalCloudId}>
              <Button type="primary">分配业务员</Button>
            </Shengpi2>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div style={cStyle}>
            <div style={tStyle}>客户信息</div>
            <Row style={dStyle}>
              <Col span={8}>
                客户名称：
                {companyInfo.legalCloudId ? (
                  <Link to={"/PayCompanyManageDetail/" + companyInfo.legalCloudId}>
                    {companyInfo.companyName} {"查看服务明细>>"}
                  </Link>
                ) : (
                  companyInfo.companyName || "-"
                )}
                {(MainBodyList || []).length ? (
                  <Popover
                    placement="right"
                    content={
                      <div>
                        {(MainBodyList || []).map((v, i) => (
                          <h3>
                            {i + 1}、{v.companyName}
                          </h3>
                        ))}
                      </div>
                    }
                    title={null}
                    trigger="hover"
                  >
                    &emsp;&emsp; <a>{(MainBodyList || []).length + "家关联主体"}</a>
                  </Popover>
                ) : (
                  ""
                )}
              </Col>
              <Col span={8}>续费跟进人： {companyInfo.followUserName || "-"} </Col>
              <Col span={8}>续费意愿：{Filter.RenewIdea(companyInfo.renewIdea)}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                服务律师团名称：
                {companyInfo.teamName || "-"}
              </Col>
              <Col span={8}>联系人：{companyInfo.linkman || "-"}</Col>
              <Col span={8}>联系电话：{companyInfo.mobileNo || "-"}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>代理商：{companyInfo.brokerInfoName || "暂无"}</Col>
              <Col span={8}>法务助理： {companyInfo.assistants && companyInfo.assistants.length ? companyInfo.assistants.join("、") : "-"}</Col>
              <Col span={8}>订单数：{companyInfo.orderCount || "-"}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={24}>
                所属行业：{companyInfo.firstCategory || "-"} {companyInfo.secondCategory || ""} {companyInfo.thirdCategory || ""}
                {companyInfo.fourCategory || ""}
              </Col>
            </Row>
          </div>

          <div style={cStyle}>
            <div style={tStyle}>上次订单信息</div>
            <Row style={dStyle}>
              <Col span={8}>套餐： {packageVO.purchaseSpec ? filter.legalspecification(packageVO.purchaseSpec) : "-"}</Col>
              <Col span={8}>实际金额： {packageVO.payAmt ? packageVO.payAmt + "元" : "-"}</Col>
              <Col span={8}>购买时间： {packageVO.payTime ? Filter.timetrans(packageVO.payTime, "day") : "-"}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                到期时间：
                {packageVO.legalEndTime ? Filter.timetrans(packageVO.legalEndTime, "day") : "-"}
              </Col>
              <Col span={8}>财务备注：{packageVO.memo || "-"}</Col>
              <Col span={8}>订单备注：{packageVO.brokerMemo || "-"}</Col>
            </Row>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>企业情况概述</div>
            <Table
              dataSource={legalCompanyBasicInfo}
              columns={[
                {
                  title: "客户等级",
                  dataIndex: "level",
                  render: text => filter.PayCompanyLevel(text),
                },
                popoverTable(8, {
                  title: "主要联系人姓名",
                  dataIndex: "masterLinkMan",
                  render: text => text || "-",
                }),
                {
                  title: "性别",
                  dataIndex: "sex",
                  render: text => text || "-",
                },
                {
                  title: "年龄",
                  dataIndex: "age",
                  render: text => text || "-",
                },
                {
                  title: "职务",
                  dataIndex: "job",
                  render: text => text || "-",
                },
                {
                  title: "整体法律意识",
                  dataIndex: "lawSense",
                  render: text => text || "-",
                },
                {
                  title: "服务认可度",
                  dataIndex: "serviceAccept",
                  render: text => text || "-",
                },
                {
                  title: "付费能力",
                  dataIndex: "paymentPower",
                  render: text => text || "-",
                },
                {
                  title: "营收规模",
                  dataIndex: "incomeScale",
                  render: text => text || "-",
                },
                {
                  title: "备注",
                  dataIndex: "memo",
                  width: 1100,
                },
              ]}
              size="middle"
              pagination={false}
              bordered={true}
              scroll={{ x: "max-content" }}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>
              续费沟通记录
              <span
                style={{
                  marginLeft: "70px",
                  fontWeight: 400,
                }}
              >
                是否继续跟进：{dataInfo.isKeepFollow == 1 ? "是" : dataInfo.isKeepFollow == 2 ? "否" : "-"}&emsp; 下次跟进时间：{Filter.timetrans(dataInfo.nextFollowDate) || "-"}
              </span>
              <AddBroker getData={this.getData} getDataList={this.getDataList} />
            </div>
            <Table
              dataSource={this.state.DateList}
              columns={[
                {
                  title: "续费意愿",
                  dataIndex: "renewIdea",
                  width: 50,
                  render: text => Filter.RenewIdea(text),
                },
                popoverTable(8, {
                  title: "不续费时原因",
                  dataIndex: "reason",
                }),
                {
                  title: "沟通角色",
                  dataIndex: "followType",
                  width: 60,
                  render: text => (text == 1 ? "客户" : text == 2 ? "合作商" : "-"),
                },
                popoverTable(8, {
                  title: "沟通内容",
                  dataIndex: "followDesc",
                }),
                {
                  title: "本次跟进时间",
                  dataIndex: "followTime",
                  render: text => Filter.timetrans(text),
                  width: 60,
                },
                {
                  title: "跟进人",
                  dataIndex: "operUserName",
                  width: 80,
                },
              ]}
              size="middle"
              pagination={
                this.state.DateList.length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>客户服务总结及建议</div>
            <Table
              dataSource={legalSummaryInfos}
              columns={[
                {
                  title: "总结及建议 ",
                  width: 1200,
                  dataIndex: "summary",
                },
                {
                  title: "添加时间",
                  dataIndex: "timeCreated",
                },
                {
                  title: "添加人",
                  dataIndex: "operName",
                },
                {
                  title: "操作",
                  dataIndex: "id",
                  render: (text, record) =>
                    record.status != 0 && record.status != 1 ? (
                      <a
                        onClick={() => {
                          confirm({
                            title: "确定需要与总部业务人员沟通客户具体事宜吗？",
                            icon: null,
                            maskClosable: true,
                            content: (
                              <div>
                                <p>确认后，总部业务人员会和您联系，请耐心等待</p>
                                <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.ConfirmRef}>
                                  <Form.Item label="备注" style={{ width: "100%" }} name="memo">
                                    <Input.TextArea maxLength={300} placeholder="请输入" style={{ width: "100%" }} rows={5} />
                                  </Form.Item>
                                </Form>
                              </div>
                            ),
                            onOk: () => {
                              this.ConfirmRef.current
                                .validateFields()
                                .then(async values => {
                                  console.log(values, "valuesvalues");
                                  values.id = text;
                                  try {
                                    Axios("post", "/renew/connect", values).then(res => {
                                      if (res.errorCode == "200" || res.isSuccess == "T") {
                                        message.success(res.errorMsg);
                                        this.getData();
                                      } else {
                                      }
                                    });
                                  } catch (err) {
                                    // message.error(err);
                                  }
                                })
                                .catch(info => {
                                  console.log("Validate Failed:", info);
                                });
                            },
                            onCancel () {
                              console.log("Cancel");
                            },
                          });
                        }}
                      >
                        需与总部沟通
                      </a>
                    ) : (
                      "需与总部沟通"
                    ),
                },
              ]}
              size="middle"
              scroll={{ x: "max-content" }}
              pagination={
                this.state.DateList.length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>服务记录</div>
            <Tabs defaultActiveKey="1">
              <TabPane tab={`常规法律服务（${serviceTotalNum1 || 0}）`} key="1">
                {((serviceList1.consultLawyerCount || serviceList1.contractCount || serviceList1.letterCount || serviceList1.prosecuteCount) && (
                  <p>
                    {" "}
                    截至最新，
                    {(serviceList1.consultLawyerCount && "【提供" + serviceList1.consultLawyerCount + "次法律咨询服务】") || ""}
                    {(serviceList1.contractCount && "【审核拟定" + serviceList1.contractCount + "份合同】") || ""}
                    {(serviceList1.letterCount && "【起草" + serviceList1.letterCount + "份催款函/律师函】") || ""}
                    {(serviceList1.prosecuteCount && "【起草" + serviceList1.prosecuteCount + "份诉讼文书】") || ""}
                  </p>
                )) ||
                  ""}
                <Table
                  dataSource={serviceList1.list}
                  columns={[
                    {
                      title: "服务ID",
                      dataIndex: "id",
                    },
                    popoverTable(8, {
                      title: "服务项",
                      dataIndex: "legalServiceItemName",
                    }),
                    {
                      title: "律师",
                      dataIndex: "lawyerName",
                    },
                    {
                      title: "业务领域",
                      dataIndex: "businessDomain",
                      render: r => Filter.BusinessDomain(r),
                    },
                    {
                      title: "服务方式",
                      dataIndex: "legalServiceMode",
                      render: r => Filter.LegalServiceMode(r),
                    },
                    {
                      title: "服务时长",
                      dataIndex: "legalServiceHour",
                      render: r => (r ? r + " " + "小时" : "-"),
                    },
                    popoverTable(8, {
                      title: "服务内容",
                      dataIndex: "memo",
                    }),
                    popoverTable(8, {
                      title: "备注",
                      dataIndex: "remark",
                    }),
                    {
                      title: "服务时间",
                      dataIndex: "serviceTime",
                    },
                    {
                      title: "创建时间",
                      dataIndex: "timeCreated",
                    },
                  ]}
                  size="middle"
                  pagination={{
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                    total: Number(serviceTotalNum1),
                  }}
                  loading={serviceLoading1}
                  bordered={true}
                  onChange={pagination => {
                    this.getServiceList1(pagination.current);
                  }}
                />
              </TabPane>
              <TabPane tab={`账款非诉清收（${serviceTotalNum || 0}）`} key="2">
                <Table
                  dataSource={serviceList}
                  columns={[
                    {
                      title: "服务类型",
                      dataIndex: "record",
                      render: () => "应收账款非诉清收",
                    },
                    {
                      title: "业务领域",
                      dataIndex: "record1",
                      render: () => "财税账款",
                    },
                    {
                      title: "欠款对象",
                      dataIndex: "payerName",
                    },
                    {
                      title: "服务时长",
                      dataIndex: "legalServiceHour",
                      render: r => (r ? r + " " + "小时" : "-"),
                    },
                    popoverTable(8, {
                      title: "服务内容",
                      dataIndex: "claimDesc",
                    }),
                    {
                      title: "催收负责人",
                      dataIndex: "operNames",
                      render: text => text.toString(),
                    },
                    {
                      title: "完成时间",
                      dataIndex: "finishTime",
                      render: time => (time ? time : "-"),
                    },
                  ]}
                  size="middle"
                  pagination={{
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                    total: Number(serviceTotalNum),
                  }}
                  loading={serviceLoading}
                  bordered={true}
                  onChange={pagination => {
                    this.getServiceList(pagination.current);
                  }}
                />
              </TabPane>
            </Tabs>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>服务评价</div>
            <Table
              dataSource={Investigate}
              columns={[
                {
                  title: "调研节点",
                  dataIndex: "surveyNode",
                  render: text => text || "-",
                },
                {
                  title: "整体满意度",
                  dataIndex: "satisf",
                  render: text => text || "-",
                },
                {
                  title: "发送时间",
                  dataIndex: "sendTime",
                  render: text => text || "-",
                },
                {
                  title: "评价状态",
                  dataIndex: "status",
                  render: text => Filter.InvestigateStatus(text),
                },
                {
                  title: "评价时间",
                  dataIndex: "evaluateTime",
                  render: text => text || "-",
                },
                {
                  title: "操作",
                  dataIndex: "operate",
                  render: (text, record) => <span>{record.status == 1 ? <a onClick={() => this.DownloadReport(record.id)}>下载报告</a> : "下载报告"}</span>,
                },
              ]}
              size="middle"
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
                total: Number(InvestigateNum),
              }}
              loading={InvestigateLoading}
              bordered={true}
              onChange={pagination => {
                this.getInvestigateList(pagination.current);
              }}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>操作记录</div>
            <Tables code={2} detailCustId={companyInfo.legalCloudId} ref={this.TablesRef} />
          </div>
        </div>
      </div>
    );
  }
}

let Main = connect(
  state => {
    return state;
  },
  dispatch => ({
    actions: bindActionCreators({}, dispatch),
  })
)(MainM);
export default Main;

class EditM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
      brokerList: [],
    };
  }

  componentWillMount () { }
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        this.setState({
          confirmLoading: true,
        });
        values.legalRenewId = detailCustId;
        try {
          let res = await Axios("PUT", "/renew/renewIdea", values);
          this.setState({
            confirmLoading: false,
          });
          if (res.isSuccess == "T") {
            message.success(res.errorMsg);
            this.formRef.current.resetFields();
            this.setState({
              visible: false,
            });
            this.props.getData();
            this.props.getDataList(1);
          } else {
            message.error(res.errorMsg);
          }
        } catch (err) {
          // message.error(err);
          this.setState({ loading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  render () {
    const data = this.props.record;
    const { province, city } = this.state;
    console.log(data);
    console.log(province, city);
    return (
      <span>
        <span onClick={this.showModal}>
          <FormOutlined style={{ cursor: "pointer" }} />
        </span>
        <Modal
          confirmLoading={this.state.loading}
          title="续费意愿"
          open={this.state.visible}
          bodyStyle={{
            maxHeight: document.body.clientHeight - 260 + "px",
            overflow: "auto",
          }}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
        >
          <Form layout="horizontal" labelCol={{ span: 6 }} ref={this.formRef} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="续费意愿"
              style={{ width: "100%" }}
              name="renewIdea"
              initialValue={this.props.renewIdea}
              rules={[
                {
                  required: true,
                  message: "请选择续费意愿",
                },
              ]}
            >
              <Select allowClear placeholder="请选择" style={{ width: "80%" }}>
                <Select.Option key={1} value={1}>
                  沟通中
                </Select.Option>
                <Select.Option key={2} value={2}>
                  续费中
                </Select.Option>
                <Select.Option key={3} value={3}>
                  不续费
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let Edit = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(EditM);

class Edit1M extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
      brokerList: [],
      StatusList: [],
    };
  }

  componentWillMount () { }
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        this.setState({
          confirmLoading: true,
        });
        values.legalRenewId = detailCustId;
        console.log(values);
        try {
          let res = await Axios("PUT", "/renew/followUser", values);
          this.setState({
            confirmLoading: false,
          });
          if (res.isSuccess == "T") {
            message.success(res.errorMsg);
            this.formRef.current.resetFields();
            this.setState({
              visible: false,
            });
            this.props.getData();
            this.props.getDataList(1);
          } else {
            message.error(res.errorMsg);
          }
        } catch (err) {
          // message.error(err);
          this.setState({ loading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  // 弹出框设置
  showModal = () => {
    this.loadData();
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };
  loadData = async () => {
    try {
      let res = await Axios("get", "/renew/followUser/" + this.props.legalCloudId, {});
      if (res.errorCode === "200" || res.isSuccess === "T") {
        this.setState({
          StatusList: res.body.list,
        });
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) { }
  };
  render () {
    const data = this.props.record;
    const { province, city } = this.state;
    return (
      <span>
        <span onClick={this.showModal}>
          <FormOutlined style={{ cursor: "pointer" }} />
        </span>
        <Modal
          confirmLoading={this.state.loading}
          title="编辑续费跟进人"
          open={this.state.visible}
          bodyStyle={{
            maxHeight: document.body.clientHeight - 260 + "px",
            overflow: "auto",
          }}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
        >
          <Form ref={this.formRef} layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="续费跟进人"
              style={{ width: "100%" }}
              name="userId"
              initialValue={this.props.followUserId}
              rules={[
                {
                  required: true,
                  message: "请选择续费跟进人",
                },
              ]}
            >
              <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                {this.state.StatusList.length
                  ? this.state.StatusList.map(v => {
                    return (
                      <Select.Option key={v.userId} value={v.userId}>
                        {v.userName}
                      </Select.Option>
                    );
                  })
                  : ""}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let Edit1 = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(Edit1M);

class AddBrokerM extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      confirmLoading: false,
      isTrue: 1,
    };
  }

  componentWillMount () { }
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        if (values.followDesc.length < 5) {
          message.info("内容至少输入5个字");
          return;
        }
        if (values.renewIdea == "3") {
          values.reason = values.reason.toString() + "" + (values.reasonmome ? "," + values.reasonmome : "");
        }
        this.setState({
          confirmLoading: true,
        });
        values.followTime = values.followTime.format("YYYY-MM-DD HH:mm:ss");
        values.nextFollowDate = values.nextFollowDate ? values.nextFollowDate.format("YYYY-MM-DD HH:mm:ss") : undefined;
        values.legalRenewId = detailCustId;

        console.log(values, "values");
        try {
          let res = await Axios("POST", "/renew/save/follow", values);
          this.setState({
            confirmLoading: false,
          });
          if (res.isSuccess == "T") {
            message.success(res.errorMsg);
            this.formRef.current.resetFields();
            this.setState({
              visible: false,
              docUrl: "",
            });
            console.log(this.props, "this.props");
            this.props.getData();
            this.props.getDataList(1);
          } else {
            message.error(res.errorMsg);
            this.setState({
              confirmLoading: false,
            });
          }
        } catch (err) {
          // message.error(err);
          this.setState({ loading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };
  render () {
    const data = this.props.record;
    const { province, city } = this.state;
    console.log(data);
    console.log(province, city);
    return (
      <span>
        <Button onClick={this.showModal} type="primary" style={{ float: "right" }} icon={<PlusOutlined />}>
          添加沟通记录
        </Button>

        <Modal
          confirmLoading={this.state.loading}
          title="添加跟进记录"
          open={this.state.visible}
          bodyStyle={{
            maxHeight: document.body.clientHeight - 260 + "px",
            overflow: "auto",
          }}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          width={620}
        >
          <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
            <Form.Item
              label="续费意愿"
              style={{ width: "100%" }}
              name="renewIdea"
              rules={[
                {
                  required: true,
                  message: "请选择续费意愿",
                },
              ]}
            >
              <Select allowClear placeholder="请选择" >
                <Select.Option key="4">有意愿</Select.Option>
                <Select.Option key="1">沟通中</Select.Option>
                <Select.Option key="2">续费中</Select.Option>
                <Select.Option key="3">不续费</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.renewIdea !== currentValues.renewIdea}>
              {({ getFieldValue }) =>
                getFieldValue("renewIdea") === "3" ? (
                  <Form.Item
                    label="原因"
                    style={{ width: "100%" }}
                    name="reason"
                    rules={[
                      {
                        required: true,
                        message: "请选择原因",
                      },
                    ]}
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={8}>
                          <Checkbox value="购买后未使用">购买后未使用</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="需求较少">需求较少</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="服务不满意">服务不满意</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="虚假承诺">虚假承诺</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="公司经营异常">公司经营异常</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="公司内部决策">公司内部决策</Checkbox>
                        </Col>
                        <Col span={9}>
                          <Checkbox value="空号/无人接听">空号/无人接听</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="其他">其他</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.reason !== currentValues.reason}>
              {({ getFieldValue }) =>
                (getFieldValue("reason") || []).includes("其他") ? (
                  <Form.Item
                    label={""}
                    style={{ width: "100%" }}
                    name="reasonmome"
                    rules={[
                      {
                        required: false,
                        message: "请输入原因",
                      },
                    ]}
                  >
                    <Input.TextArea maxLength={200} minLength={5} rows={4} placeholder="请输入" style={{ width: "80%", marginLeft: "33%" }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item
              label="沟通角色"
              style={{ width: "100%" }}
              name="followType"
              rules={[
                {
                  required: true,
                  message: "请选择沟通角色",
                },
              ]}
            >
              <Select allowClear placeholder="请选择">
                <Select.Option key="1">客户</Select.Option>
                <Select.Option key="2">合作商</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="沟通内容"
              name="followDesc"
              rules={[
                {
                  required: true,
                  message: "沟通内容不能为空",
                },
              ]}
            >
              <Input.TextArea maxLength={200} minLength={5} rows={4} placeholder="请填写沟通内容，至少5个字" />
            </Form.Item>
            <Form.Item
              label="本次跟进时间"
              name="followTime"
              rules={[
                {
                  required: true,
                  message: "请选择时间",
                },
              ]}
              initialValue={moment(new Date())}
            >
              <DatePicker
                placeholder="请选择"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: "100%" }}
                onChange={e => {
                  this.formRef.current.setFieldsValue({
                    nextFollowDate: moment(e * 1 + 86400000 * 7),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="是否继续跟进"
              style={{ width: "100%" }}
              name="isKeepFollow"
              rules={[
                {
                  required: true,
                  message: "请选择是否继续跟进",
                },
              ]}
              onChange={e => this.setState({ isTrue: e })}
              initialValue="1"
            >
              <Select allowClear placeholder="请选择">
                <Select.Option key="1">是</Select.Option>
                <Select.Option key="2">否</Select.Option>
              </Select>
            </Form.Item>
            {this.state.isTrue == 1 && (
              <Form.Item
                label="下次跟进时间"
                name="nextFollowDate"
                rules={[
                  {
                    required: true,
                    message: "请选择时间",
                  },
                ]}
                initialValue={moment(new Date() * 1 + 86400000 * 7)}
              >
                <DatePicker placeholder="请选择" showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: "100%" }} />
              </Form.Item>
            )}
          </Form>
        </Modal>
      </span>
    );
  }
}

let AddBroker = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(AddBrokerM);
